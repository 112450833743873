/**
 *  set up the dropdown menu
 */
class DropdownMenu {
  constructor(containerSelector, foundation) {
    this.foundation = foundation;
    this.topTierThreeContainer = $(containerSelector);

    if ($(this.topTierThreeContainer).length) {
      this.dropdownContainer = $(this.topTierThreeContainer).find('.overflow-link');
      this.links = $(this.topTierThreeContainer).find('>a');
      this.dropdownLinks = this.dropdownContainer.find('.overflow-dropdown > a');
      let dropdownContainerLabel = this.dropdownContainer.find('>a');

      if (this.links.length && dropdownContainerLabel.length) {
        this.originalTop = dropdownContainerLabel[0].offsetTop;
        this.initDropdown();

        $(window).on(
          'resize',
          this.foundation.util.throttle(function() {
            this.initDropdown();
          }, 100)
        );
      }
    }
  }

  initDropdown() {
    let hasDrop = false;
    let hasWrap = false;

    this.dropdownLinks.hide();

    const _links = this.links;
    const _dropDownLinks = this.dropdownLinks;

    this.links.each(function(key, value) {
      hasWrap = _links[key + 1] && _links[key + 1].offsetLeft < value.offsetLeft;

      if (!hasDrop && hasWrap) {
        hasDrop = true;
        _dropDownLinks.slice(key).show();
        return false;
      }
    });

    this.dropdownContainer.toggle(hasDrop);
  }
}

module.exports = DropdownMenu;
