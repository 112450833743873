const DropdownMenu = require('./header-dropdown-menu');

class HeaderBase {
  constructor(gdsFoundation) {
    this.foundation = gdsFoundation;
    this.navbar = $('.elastic-header');
    this.windowTopPosition = 0;
    this.outlookSubMenuWrapper = $('.outlook-layout-submenu__wrapper');
    this.outlookSubMenuContainer = $('.outlook-layout-submenu__container');
  }

  init() {
    this.clickTierOne();
    this.toggleSearch();
    this.activateTierThree();
    this.deactivateTierThree();
    this.toggleOverflowLink();
    this.toggleMenu();
    this.openSubMenu();
    this.closeSubMenu();
    this.closeTierThreeMenu();
    this.toggleLogin();
    this.toggleLogout();
    this.togglePersonalInvstingTab();
    this.toggleLanguageSelectorTab();

    this.setupMenu();

    this.toggleScrollUp();
    this._makeWayFindingBarLinksFocusable(false);
    this._makeSubMenuLinksFocusable(false);

    this.tierThreeDropDown = new DropdownMenu(
      '.tier-three-bar .drop-down-menu-container',
      this.foundation
    );

    this.outlookSubnav();
  }

  initializeFocusableElements() {
    const focusableElementsList = [
      'a[href]',
      'button:not([disabled])',
      'area[href]',
      'input:not([disabled])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      'iframe',
      'object',
      'embed',
      '*[tabindex]',
      '*[contenteditable]'
    ];
    return focusableElementsList.join();
  }

  _makeSearchBarFocusable(enabled) {
    const $searchWrapper = $('.input-holder.yxt-SearchBar-wrapper');
    const focusableElementsSelector = this.initializeFocusableElements();
    if (enabled) {
      $searchWrapper.find(`${focusableElementsSelector}`).removeAttr('tabindex');
      $searchWrapper.next('#close-search').removeAttr('tabindex');
    } else {
      $searchWrapper.find(`${focusableElementsSelector}`).attr('tabindex', '-1');
      $searchWrapper.next('#close-search').attr('tabindex', -1);
    }
  }

  _makeWayFindingBarLinksFocusable(enabled) {
    const $wayFindingMenu = $('#globalSection .sub-menu');
    const focusableElementsSelector = this.initializeFocusableElements();
    if (enabled) {
      $wayFindingMenu.find(`${focusableElementsSelector}`).removeAttr('tabindex');
    } else {
      $wayFindingMenu.find(`${focusableElementsSelector}`).attr('tabindex', '-1');
    }
  }

  _makeSubMenuLinksFocusable(enabled, mainMenu) {
    const focusableElementsSelector = this.initializeFocusableElements();
    if (mainMenu) {
      const $mainMenu = $(mainMenu);
      if (enabled) {
        $mainMenu
          .next('.megamenu')
          .find(focusableElementsSelector)
          .removeAttr('tabindex');
        $mainMenu.nextAll('.close-megamenu').removeAttr('tabindex');

        //When tier 2 active --> Add tabindex -1 to Tier 3
        $mainMenu
          .next('.megamenu')
          .find(`.tier-three ${focusableElementsSelector}`)
          .attr('tabindex', '-1');
        $mainMenu
          .next('.megamenu')
          .find('.tier-three-return')
          .attr('tabindex', '-1');
      } else {
        $mainMenu
          .next('.megamenu')
          .find(focusableElementsSelector)
          .attr('tabindex', '-1');
        $mainMenu.nextAll('.close-megamenu').attr('tabindex', '-1');

        //When Tier-3 active --> Make close button active (Removing tabindex)
        $mainMenu
          .parent()
          .find('.close-megamenu')
          .removeAttr('tabindex');
      }
    } else {
      const $mainMenu = $('.main-header .main-menu');
      if (enabled) {
        $mainMenu.find(`.megamenu ${focusableElementsSelector}`).removeAttr('tabindex');
        $mainMenu.find('.close-megamenu').removeAttr('tabindex');
      } else {
        $mainMenu.find(`.megamenu ${focusableElementsSelector}`).attr('tabindex', '-1');
        $mainMenu.find('.close-megamenu').attr('tabindex', '-1');
        $mainMenu.find('#close-search').attr('tabindex', '-1');
      }
    }
  }

  clickTierOne() {
    const that = this;
    $('.tier-one').on('click', function() {
      if ($(this).hasClass('active-megamenu')) {
        $('.active-megamenu').removeClass('active-megamenu');
        $('.ancestor').removeClass('active-ancestor');
        localStorage.setItem('menuOpen', false);
        that._makeSubMenuLinksFocusable(false, this);
      } else {
        $('.active-megamenu').removeClass('active-megamenu');
        $(this).addClass('active-megamenu');
        localStorage.setItem('menuOpen', true);
        if (!$(this).hasClass('ancestor')) {
          $('.ancestor').addClass('active-ancestor');
        } else {
          $('.ancestor').removeClass('active-ancestor');
        }
        that._makeSubMenuLinksFocusable(false, $('.active-megamenu')[0]);
        that._makeSubMenuLinksFocusable(true, this);
      }

      $('.tier-three').removeClass('active');
      $('.overflow-link.active').removeClass('active');
    });
  }

  toggleSearch() {
    const that = this;
    $('.close-megamenu, .backdrop, .toggle-search').on('click touch', function() {
      if (!$(this).hasClass('toggle-search')) {
        //On close --> All menu/submenu items tabindex set to -1
        that._makeSubMenuLinksFocusable(false);
      }
      $('.active-megamenu').removeClass('active-megamenu');
      localStorage.setItem('menuOpen', false);
      $('.ancestor').removeClass('active-ancestor');
      $('.tier-three').removeClass('active');
    });
  }

  activateTierThree() {
    const that = this;
    $('.tier-three-link').on('click touch', function() {
      $(this)
        .next()
        .addClass('active');

      //On tier 3 active --> Add tabindex -1 to tier 2 nav
      that._makeSubMenuLinksFocusable(false, $('.active-megamenu')[0]);
      const focusableElementsSelector = that.initializeFocusableElements();
      const $mainMenu = $(this);
      $mainMenu
        .next('.tier-three')
        .find(focusableElementsSelector)
        .removeAttr('tabindex');
      $mainMenu.nextAll('.tier-three-return').removeAttr('tabindex');
    });
  }

  deactivateTierThree() {
    const that = this;
    $('.tier-three-return').on('click touch', function() {
      $(this)
        .parent()
        .removeClass('active');
      // On clicking back button from tier 3 --> make tier 2 active and remove focus from tier 3 (by adding tabindex=-1)
      that._makeSubMenuLinksFocusable(true, $('.active-megamenu')[0]);
    });
  }

  toggleOverflowLink() {
    $('.overflow-link').on('click touch', function() {
      $(this).toggleClass('active');
    });
  }

  // Mobile menu button events. These are hidden on large screens so no
  // problem to register event listeners.
  toggleMenu() {
    $('.menu-toggle').on('click touch', function() {
      const $mainHeader = $('.main-header');
      const isMenuOpened = $(this).hasClass('is-open');

      $mainHeader.toggleClass('is-open', !isMenuOpened);
      $('.active-megamenu').removeClass('active-megamenu');

      let otherMenuToggler = $('.menu-toggle.is-open')[0];
      if (otherMenuToggler && otherMenuToggler !== this) {
        $(otherMenuToggler).removeClass('is-open');
      }

      $(this).toggleClass('is-open');

      if ($mainHeader.hasClass('is-sub-open')) {
        $mainHeader.removeClass('is-sub-open');
        $('.megamenu .category-body.active').removeClass('active');
      }
    });
  }

  openSubMenu() {
    $('.category .category-header').on('click touch', function() {
      $(this)
        .next('.category-body')
        .addClass('active')
        .parents('header.main-header')
        .addClass('is-sub-open');
    });
  }

  closeSubMenu() {
    $('.category-body-return').on('click touch', function() {
      $(this)
        .closest('.category-body')
        .removeClass('active')
        .closest('header.main-header')
        .removeClass('is-sub-open');

      $('body').removeClass('is-mobile is-sub-open');
    });
  }

  closeTierThreeMenu() {
    $('.tier-three-return').on('click touch', function() {
      $(this)
        .closest('.tier-three')
        .removeClass('active');
    });
  }

  toggleLogin() {
    $('.fil-header .login').on('click touch', function() {
      let $loginLink = $('#globalSection .wfb-nav:not(.logged-in) .login');
      if ($loginLink.length) {
        $loginLink[0].click();
      }
    });
  }

  toggleLogout() {
    $('.fil-header .logout').on('click touch', function() {
      let $logoutLink = $('#globalSection .wfb-nav.logged-in .logout');
      if ($logoutLink.length) {
        $logoutLink[0].click();
      }
    });
  }

  /**
   * @description - Global nav drop down onClick functions
   */
  togglePersonalInvstingTab() {
    const that = this;
    $('.sub-menu-trigger').on('click touch', function() {
      const isOpen = $('.personal-investing-tab').hasClass('is-open');
      if (isOpen) {
        that._makeWayFindingBarLinksFocusable(false);
      } else {
        that._makeWayFindingBarLinksFocusable(true);
      }
      $('.personal-investing-tab').toggleClass('is-open');
    });
  }

  /**
   * @description - Language drop down onClick functions
   */
  toggleLanguageSelectorTab() {
    $('.language-selector-trigger').on('click touch', function() {
      $('.language-selector-tab').toggleClass('is-open');
    });
  }

  /**
   * Setup menu for mobile layout
   *
   * Move wayfinding bar inside of the mobile menu and ensure it is displayed.
   */
  setupMobileMenu() {
    $('.global-section').insertAfter('.fil-header .main-menu .menu');

    $('.global-section').css('display', 'block');
    if ($('#globalSection .wfb-nav .login')[0]) {
      $('.fil-header .nav-bar .login').removeClass('login-disabled');
    } else {
      $('.fil-header .nav-bar .login').addClass('login-disabled');
    }

    // On mobile view - when clicked on search icon
    $('.search-toggle--yext_enabled').click(function() {
      $('.tier-one-menu--yext_enabled').hide();
      $('.header-wrapper .global-section').hide();
      $('.search-menu--yext_enabled').show();

      $('.header-wrapper .js-yext-submit .yxt-SearchBar-buttonImage').text('Search');
      $('.header-wrapper .yext-search-enabled .cross-button').remove();
    });

    // On mobile view - when clicked on menu icon
    $('.menu-toggle--yext_enabled').click(function() {
      $('.search-menu--yext_enabled').hide();
      $('.tier-one-menu--yext_enabled').show();
      $('.header-wrapper .global-section').show();
    });
  }
  /**
   * Setup menu for desktop layout
   *
   * Move wayfinding bar outside of and above the menu and ensure it is
   * displayed.
   */
  setupDesktopMenu() {
    // Closing all the menus when going back on desktop view
    $('.main-header').removeClass('is-open');
    $('.menu-toggle').removeClass('is-open');

    $('.global-section').prependTo('#body-container > main');

    // Adding back both, menu items and search bar on desktop view
    $('.search-menu--yext_enabled').show();
    $('.tier-one-menu--yext_enabled').show();
    $('.header-wrapper .global-section').show();
  }

  toggleScrollUp() {
    if ($(this.navbar).length > 0) {
      $(window).scroll(function() {
        const scrollTop = $(window).scrollTop();
        const itemHeight = $(this.navbar).height();
        if (this.windowTopPosition < scrollTop && scrollTop > itemHeight * 2) {
          $(this.navbar).addClass('scrollUp');
        } else if (this.windowTopPosition > scrollTop && scrollTop > itemHeight) {
          $(this.navbar).removeClass('scrollUp');
        }
        this.windowTopPosition = scrollTop;
      });
    }
  }

  outlookSubnav() {
    if (this.outlookSubMenuWrapper.length) {
      this.subMenuContainerScrollLeft();
      $(window).on('orientationchange', this.subMenuContainerScrollLeft());
    }
  }

  // Scroll the outlook subnav to the selected/active node
  subMenuContainerScrollLeft() {
    let totalWidth = this.outlookSubMenuWrapper.outerWidth();
    this.outlookSubMenuContainer.css('width', totalWidth);
    let $selectedItem = this.outlookSubMenuContainer.find('.selected').parent();

    let myScrollPos =
      $selectedItem.offset().left +
      $selectedItem.outerWidth(true) / 2 +
      this.outlookSubMenuContainer.scrollLeft() -
      this.outlookSubMenuContainer.width() / 2;

    this.outlookSubMenuContainer.scrollLeft(myScrollPos);
  }

  setupMenu() {
    /**
     * Detect and handle switch between mobile and desktop breakpoints
     */
    $(window).on('changed.zf.mediaquery', () => {
      if (this.foundation.MediaQuery.atLeast('large')) {
        this.setupDesktopMenu();
      } else {
        this.setupMobileMenu();
      }
    });

    /**
     * Desktop is the default layout. On initial load, check the current
     * breakpoint and setup the mobile menu if required.
     */
    if (window.innerWidth < 1024) {
      this.setupMobileMenu();
    }
  }
}

module.exports = HeaderBase;
