$(document).ready(function() {
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts
        .pop()
        .split(';')
        .shift();
    }
  }
  let processPopup = function() {
    let ceCookie = getCookie('isPopupToBeHidden');
    if (ceCookie) {
      console.log('Cookie Present');
    } else {
      if ($('#auto-modal').data('popup')) {
        $('#auto-modal').foundation();
        $('#auto-modal').foundation('open');
        $('.fil-index-list ul li').on('click', function() {
          let CookieDate = new Date();
          CookieDate.setFullYear(CookieDate.getFullYear() + 1);
          document.cookie = `isPopupToBeHidden=true; path=/; expires=${CookieDate.toGMTString()};`;
          $('#auto-modal').foundation('close');
        });
      }
    }
  };
  if (window.location.href.includes('/important-policies/cookie-policy') == false) {
    processPopup();
  }
});
