/* global Foundation */

const queryString = require('qs');

const PAGINATION_BUTTONS = 5;
const PAGINATION_BUTTONS_MOBILE = 3;
const PAGE_PARAM = 'p';
const PER_PAGE_PARAM = 'c';

/**
 * Paginator
 */
class Paginator {
  constructor(rootElement, opts = {}) {
    this.perPage = 10;
    this.page = 0;

    // Optionally disable SEO and push state functionality
    this.disableSEO = opts.disableSEO || false;
    this.disableURL = opts.disableURL || false;

    this.$root = $(rootElement);

    // $results -- container for rendered result elements
    this.$results = this.$root.find('[data-paginator-results]');

    // $counter -- displays the total number of results
    this.$counter = this.$root.find('[data-paginator-counter]');

    // $navigation -- container for navigation button elements
    this.$navigation = this.$root.find('.paginator__nav');

    // $perPageInput -- input field for user to adjust per page setting
    this.$perPageInput = this.$root.find('[data-paginator-per-page-input]');
    this.$perPageInput.change(event => {
      this.setPerPage($(event.target).val());
    });

    // $perPageInputContainer -- container of the $perPageInput
    this.$perPageInputContainer = this.$root.find('.paginator__per-page');

    // Rebuild navigation on breakpoint change
    $(window).on('changed.zf.mediaquery', () => this.buildNavigation());

    $(window).on('popstate', () => this.updateStateFromUrl());

    // Register event listeners
    this.$root.find('[data-paginator-next]').click(() => {
      this.setPage(this.page + 1);
    });

    this.$root.find('[data-paginator-prev]').click(() => {
      this.setPage(this.page - 1);
    });
  }

  /**
   * Calculate the maximum number of pages required to display all of the the
   * current results.
   *
   * @return {number} Maximum number of pages
   */
  getMaxPages() {
    const total = this.getTotalResults();
    const maxPages = total === 0 ? 0 : Math.ceil(total / this.perPage);
    return maxPages;
  }

  /**
   * Determine how many pagination buttons should be visible at once
   *
   * @return {number} Number of buttons to display
   */
  getNavButtonCount() {
    return Foundation.MediaQuery.atLeast('medium') ? PAGINATION_BUTTONS : PAGINATION_BUTTONS_MOBILE;
  }

  /**
   * Return the results for a particular page.
   *
   * @abstract
   * @param {number} page Page number to retrieve results from
   * @return {array} Array of results in a format understood by buildResult()
   */
  // eslint-disable-next-line no-unused-vars
  getResultsForPage(page) {
    throw new ReferenceError('Must implement getResultsForPage() method');
  }

  /**
   * Retrieve the total number of results
   *
   * @abstract
   * @return {number} Total results
   */
  getTotalResults() {
    throw new ReferenceError('Must implement getTotalResults() method');
  }

  /**
   * Perform some acton when the page changes
   */
  onPageChange() {
    return;
  }

  /**
   * Perform some acton when the perpage changes
   */
  onPerPageChange() {
    return;
  }

  /**
   * Perform some acton when the state (i.e. page or perpage) changes.
   */
  onStateChange() {
    return;
  }

  /**
   * Set the page number.
   *
   * @param {number} page Page number to view
   */
  setPage(page) {
    this.setState(page, this.perPage);
  }

  /**
   * Set the number of results per page. Reset the page number to 0 if the
   * per page value has changed.
   *
   * @param {number} page Number of results to display per page
   */
  setPerPage(perPage) {
    if (this.perPage !== perPage) {
      this.setState(0, perPage);
    }
  }

  /**
   * Set both the page and perPage attributes simultaneously. Perform a sanity
   * check on the new page value. Rebuild the view if either value has
   * changed.
   *
   * @param {number}  page    Page number to view
   * @param {number}  perPage Number of results to display per page
   * @param {boolean} silent  Do not trigger the state change event
   */
  setState(page, perPage, silent = false) {
    page = isNaN(page) ? 0 : page;
    perPage = isNaN(perPage) ? 1 : perPage;

    const pageChanged = this.page !== page;
    const perPageChanged = this.perPage !== perPage;

    if (pageChanged || perPageChanged) {
      // This must be set first, as getMaxPages() uses it for calculation
      this.perPage = Math.max(perPage, 1);

      const min = 0;
      const max = this.getMaxPages() - 1;
      const newPage = Math.min(Math.max(page, min), max);
      this.page = newPage;

      this.rebuild();

      if (perPageChanged) {
        this.$perPageInput.val(this.perPage);
      }

      if (!silent) {
        this.onStateChange();

        if (pageChanged) {
          this.onPageChange();
        }

        if (perPageChanged) {
          this.onPerPageChange();
        }

        this.$root.trigger('paginator:results-height-changed');
      }
    }
  }

  /**
   * Build the HTML for a single result
   *
   * @abstract
   * @param {object} result Data for a single result
   * @return {HTML element} HTML element representing the result
   */
  // eslint-disable-next-line no-unused-vars
  buildResult(result) {
    throw new ReferenceError('Must implement buildResult() method');
  }

  /**
   * Rebuild both the navigation and the results
   */
  rebuild() {
    let results = this.getResultsForPage(this.page);
    let total = this.getTotalResults();

    this.buildNavigation();
    this.buildResults(results);
    this.buildTotalResultsOutput(total);
    this.updateURL();
    this.updateSEO();
  }

  /**
   * Generate the list of results to display on the current page
   *
   * @param {array} results Array of results in a format understood by
   * buildResult()
   */
  buildResults(results) {
    this.$results.empty();
    results.forEach(result => {
      this.buildResult(result).appendTo(this.$results);
    });
  }

  /**
   * Render the total number of results
   *
   * @param  {number} total Number of results to display
   */
  buildTotalResultsOutput(total) {
    this.$counter.text(total);
  }

  /**
   * Generate the HTML for the paginator navigation. Includes the next and
   * previous page arrow buttons, and digit buttons for navigating to
   * individual pages.
   */
  buildNavigation() {
    const buttonCount = this.getNavButtonCount();
    const maxPages = this.getMaxPages();
    const total = this.getTotalResults();

    // Hide the entire pagination if there are not multiple pages
    this.$navigation.toggleClass('hide', maxPages < 2);

    // Hide the container of the per page input if there are less than 10 items
    this.$perPageInputContainer.toggleClass('hide', total < 10);

    // Calculate the smallest value to display as a pagination button
    let startAt;
    startAt = this.page - Math.floor(buttonCount / 2);
    startAt = Math.min(startAt, maxPages - buttonCount);
    startAt = Math.max(0, startAt);

    // Determine whether to show the arrows
    $('.paginator__nav-button--prev').toggleClass(
      'invisible',
      this.page == 0 || maxPages <= buttonCount
    );

    $('.paginator__nav-button--next').toggleClass(
      'invisible',
      this.page == maxPages - 1 || maxPages <= buttonCount
    );

    // Remove the existing digit buttons
    this.$navigation
      .find('.paginator__nav-button--digit')
      .off('click')
      .remove();

    // Generate new digit buttons
    const limit = Math.min(maxPages, buttonCount);
    const nextButton = this.$navigation.find('.paginator__nav-button--next');

    for (let i = 0; i < limit; i++) {
      this.buildNavButton(startAt + i).insertBefore(nextButton);
    }
  }

  /**
   * Generate the HTML for an individual navigation button
   *
   * @param  {number} page Page number for the button to navigate to
   * @return {jQuery} jQuery object containing the button element
   */
  buildNavButton(page) {
    const $button = $(`
            <li class="paginator__nav-button paginator__nav-button--digit">
                <span class="paginator__nav-button-inner">${page + 1}</span>
            </li>
        `);

    const isCurrent = page === this.page;
    $button.toggleClass('paginator__nav-button--current', isCurrent);

    if (!isCurrent) {
      $button.click(() => this.setPage(page));
    }

    return $button;
  }

  /**
   * Update the pagination state based on the query string
   */
  updateStateFromUrl() {
    if (this.disableURL) {
      return;
    }

    const parsed = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    // Retrieve the per page value from the URL, or fall back to the value
    // of the dropdown selector
    const parsedPerPage = parsed[PER_PAGE_PARAM]
      ? parsed[PER_PAGE_PARAM]
      : this.$perPageInput.val();

    this.setState(Number(parsed[PAGE_PARAM]), Number(parsedPerPage), true);
  }

  /**
   * Update the link rel="next" and rel="prev" tags
   *
   * These tags link to the neighbouring pages and help improve SEO. If the
   * respective neighbour page does not exist, the <link> tag should be
   * removed completely.
   */
  updateSEO() {
    if (this.disableSEO) {
      return;
    }

    /**
     * Retrieve or generate a <link> tag
     *
     * @param  {string} type 'next' or 'prev'
     * @return {jQuery} jQuery object containing <link> tag
     */
    const getOrCreateLink = type => {
      let $link = $(`link[rel="${type}"]`);
      if (!$link.length) {
        $link = $(`<link rel="${type}">`).appendTo('head');
      }
      return $link;
    };

    /**
     * Generate the URL to link to a particular pagination page, retaining
     * the existing query string parameters.
     *
     * @param  {number} page
     * @return {string} SEO URL for specified page
     */
    const makeUrl = page => {
      const search = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true
      });

      search[PAGE_PARAM] = page;
      search[PER_PAGE_PARAM] = this.perPage;

      return `${window.location.pathname}?${queryString.stringify(search)}`;
    };

    /**
     * Update the <link> tag for the specified direction and page number
     *
     * @param  {string} type 'next' or 'prev'
     * @param  {number} page Page number
     */
    const addLink = (type, page) => {
      const url = makeUrl(page);
      const $link = getOrCreateLink(type);
      $link.prop('href', url);
    };

    /**
     * Remove <link> tag
     *
     * @param  {string} type 'next' or 'prev'
     */
    const removeLink = type => {
      $(`link[rel="${type}"]`).remove();
    };

    // rel=prev
    if (this.page === 0) {
      removeLink('prev');
    } else {
      addLink('prev', this.page - 1);
    }

    // rel=next
    if (this.page === this.getMaxPages() - 1) {
      removeLink('next');
    } else {
      addLink('next', this.page + 1);
    }
  }

  /**
   * Update the query string to record the pagination state. Use push state to
   * record the URL change in the browser history. Only push a new history
   * state if the URL has actually changed.
   */
  updateURL() {
    if (this.disableURL) {
      return;
    }

    let parsed = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    const hasChanged = parsed[PAGE_PARAM] != this.page || parsed[PER_PAGE_PARAM] != this.perPage;

    if (hasChanged) {
      parsed[PAGE_PARAM] = this.page;
      parsed[PER_PAGE_PARAM] = this.perPage;
      history.pushState({}, '', `?${queryString.stringify(parsed)}`);
    }
  }
}

module.exports = Paginator;
