$(document).ready(function() {
  $('[data-countdown]').each(function() {
    const finalDate = new Date($(this).data('countdown'));
    $(this)
      .countdown(finalDate, function(event) {
        $(this)
          .find('span.days')
          .text(event.strftime('%D'));
        $(this)
          .find('span.hours')
          .text(event.strftime('%H'));
        $(this)
          .find('span.mins')
          .text(event.strftime('%M'));
        $(this)
          .find('span.secs')
          .text(event.strftime('%S'));
      })
      .on('finish.countdown', function() {
        $(this)
          .find('.days, .hours, .mins, .secs')
          .text('00');
        $(this).addClass('disabled');
      });
  });
});
