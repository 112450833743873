$(document).ready(function() {
  // ALP-770 JP PI Welcome Page online account opening modal click tracking:
  // this function is to support sending event when found 'event_category', 'event_action', 'event_content',
  // in CTA attributes.

  $('body').on('click', 'a.button[event_category][event_action][event_content]', function() {
    const $link = $(this);

    document.dispatchEvent(
      new CustomEvent('analytics', {
        detail: {
          type: 'event',
          action: $link.attr('action') || 'global.interactionEvent',
          event_category: $link.attr('event_category'),
          event_action: $link.attr('event_action'),
          event_content: $link.attr('event_content')
        }
      })
    );
  });
});

export function callAnalytics(type, action, event_category, event_action, event_content) {
  document.dispatchEvent(
    new CustomEvent('analytics', {
      detail: {
        type: type,
        action: action,
        event_category: event_category,
        event_action: event_action,
        event_content: event_content
      }
    })
  );
}
