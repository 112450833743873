/* Inherit base scripts from FIL_FRONTEND - DON'T CHANGE THIS */

/* Include your scripts here */
/*
	Below are required javascript files


require('/virtualenv/lib/python3.5/site-packages/fil_global_frontend/core_assets/js/accordion');
*/
require('intersection-observer');

require('_fil/fil-global-frontend/index');
require('_fil/fil-global-frontend/session-timeout');
require('_fil/global-cms-search/watchlist_integration');
require('_fil/fil-global-frontend/trigger-analytics');

/*
	Add required addon javascript files below
*/
//require('_fil/global-cms-accordion/accordion');
// require('_fil/global-cms-article/main');
// require('_fil/global-cms-article-base/index');
require('_fil/global-cms-callback-form/callback-form');
require('_fil/global-cms-card/main');
require('_fil/global-cms-carousel/carousel');
//require('_fil/global-cms-cta/fil-cta');
require('_fil/global-cms-email-form/index');
require('_fil/global-cms-fnw-sitepopup/sitepopup');
require('_fil/global-cms-footer/footer');
require('_fil/global-cms-glossary/glossary');
require('_fil/global-cms-isa-saving-tool/isa-saving-tool');
//require('_fil/global-cms-modal/modal');
require('_fil/global-cms-refer-form/index');
require('_fil/global-cms-search/search_core');
//require('_fil/global-cms-showhide/show-hide');
require('_fil/global-cms-table/table-scroll');
require('_fil/global-cms-tabs/tabs');
require('_fil/global-cms-video-gallery/main.js');
require('_fil/global-cms-way-finding-bar/global-nav');
require('_fil/global-cms-marketo-form/marketo');
require('_fil/global-cms-navigation/index');
require('_fil/global-cms-hero-homepage/homepage_image');
require('_fil/global-cms-hero/hero_image');

/*
	Add required site specific javascript files below
*/
